<template>
    <div style="height: calc(100vh - 120px);">
        <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
        <listTable
            :list="list"
            :page="page"
            :loading="loading"
            :total="total"
            :pageSize="pageSize"
            :columns="columns"
            @page-change="pageChange" />
        <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
            <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
                <Form :columns="addColumns" :type="typeForm" @submit="submit" />
            </div>
        </el-drawer>
        <el-drawer v-model="formDrawerVisible2" size="30%" title="详情">
            <div v-if="formDrawerVisible2" style="padding-left: 16px; padding-right: 16px;">
                <formSee :columns="addColumns" />
            </div>
        </el-drawer>
    </div>
</template>
<script>
import listToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import formSee from '@/components/system/formSee.vue'
import Form from '@/components/system/form.vue'
import { agencyDel, agencyList, agencySubmit, updateStatus } from '@/apis/common'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    components: {
        listToolbar,
        listTable,
        Form,
        formSee
    },
    data() {
        return {
            info: JSON.parse(sessionStorage.getItem('user')) || {},
            formDrawerVisible: false,
            formDrawerVisible2: false,
            page: 1,
            pageSize: 15,
            total: 0,
            formData: {
                name: '',
                link_name: '',
                link_phone: ''
            },
            toolbar: [
                {type: 'input', label: '机构名称', model: 'name', value: ''},
                {type: 'input', label: '联系人', model: 'link_name', value: ''},
                {type: 'input', label: '联系电话', model: 'link_phone', value: ''},
                {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
                {type: 'button', label: '添加', def: 'primary',key: 'add'}
            ],
            list: [],
            loading: false,
            columns: [
                {label: '机构ID', prop: 'id', width: 100, type: 'text'},
                {label: '联系人', prop: 'link_name', width: 100, type: 'text'},
                {label: '联系电话', prop: 'link_phone', width: 100, type: 'text'},
                {label: '学校数', prop: 'school_num', width: 100, type: 'text'},
                {label: '教师数', prop: 'teacher_num', width: 100, type: 'text'},
                {label: '学生数', prop: 'student_num', width: 100, type: 'text'},
                {label: '状态', prop: 'status', width: 100, type: 'switch', change: (e,v) => this.changeStatus(e,v)},
                {label: '地址', prop: 'address', width: 200, type: 'text'},
                {label: '创建时间', prop: 'created_at', width: 150, type: 'text'},
                {label: '备注', prop: 'note', width: 200, type: 'text'},
                {label: '操作', width: 260, type: 'but', arr: [
                    {type: 'default', icon: 'el-icon-document', label: '查看', onClick: (row) => this.seeDetails(row)},
                    {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
                    {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
                ]}
            ],
            addFormData: {},
            addColumns: [
                {label: '机构名称', prop: 'name', type: 'input',value: ''},
                {label: '联系人', prop: 'link_name', type: 'input',value: ''},
                {label: '联系电话', prop: 'link_phone', type: 'input',value: ''},
                {label: '省份', prop: 'province_id', type: 'province',value: ''},
                {label: '城市', prop: 'city_id', type: 'city',value: ''},
                {label: '详细地址', prop: 'address', type: 'input',value: ''},
                {label: '最大学校数', prop: 'max_school_num', type: 'input',value: ''},
                {label: '账号数', prop: 'account_num', type: 'input',value: ''},
                {label: '备注', prop: 'note', type: 'textarea',value: ''},
                {label: '账号信息', prop: 'account_json', type: 'arr',value: []}
            ],
            typeForm: 'add'
        }
    },
    mounted() {
        this.getAgencyList()
    },
    methods: {
        changeStatus(e,v) {
            updateStatus({
                admin_id: this.info.id,
                agency_id: v.id,
                status: e ? 1 : 0
            }).then(res => {
            })
        },
        // 删除
        del(row) {
            this.addFormData = row
            ElMessageBox.alert('确定要删除吗?', '删除', {
                confirmButtonText: 'OK',
                callback: (e) => {
                    this.getAgencyDel()
                },
            })
        },
        getAgencyDel() {
            agencyDel({
                admin_id: this.info.id,
                agency_id: this.addFormData.id
            }).then(res => {
                ElMessage({
                    showClose: true,
                    message: '删除成功',
                    type: 'success',
                })
                this.getAgencyList()
            })
        },
        submit(e,v) {
            this.addAgencySubmit(e,v)
        },
        // 查看
        seeDetails(row) {
            this.addColumns.forEach(item => {
                item.value = row[item.prop]
                if(item.prop === 'account_json') {
                    item.value = row.account
                }
            })
            this.formDrawerVisible2 = true
        },
        // 修改
        modify(row) {
            this.addFormData = row
            this.typeForm = 'edit'
            this.addColumns.forEach(item => {
                item.value = row[item.prop]
                if(item.prop === 'account_json') {
                    item.value = row.account
                }
            })
            this.formDrawerVisible = true
        },
        // 分页
        pageChange(e) {
            this.page = e
            this.getAgencyList()
        },
        // 添加
        addTap() {
            this.typeForm = 'add'
            this.addColumns.forEach(item => {
                item.value = ''
            })
            this.formDrawerVisible = true
        },
        // 查询
        toolTap(e) {
            this.formData = e
            this.page = 1
            this.getAgencyList()
        },
        addAgencySubmit(v,n) {
            let form = {
                admin_id: this.info.id,
                agency_id: n === 'edit' ? this.addFormData.id : '',
                ...v
            }
            agencySubmit(form).then(res => {
                this.formDrawerVisible = false
                ElMessage({
                    showClose: true,
                    message: '添加成功',
                    type: 'success',
                })
                this.getAgencyList()
            })
        },
        getAgencyList() {
            this.loading = true
            agencyList({
                page: this.page,
                pagesize: this.pageSize,
                admin_id: this.info.id,
                ...this.formData
            }).then(res => {
                this.total = res.data.data.count
                this.list = res.data.data.list.map(x => {
                    x.status = x.status === 1
                    return x
                })
                this.loading = false
            })
        }
    }
}
</script>
